@if (!isHidden) {
	<div
		*ngIf="!isCollapsed"
		class="collapsed-container">
		<epic-icon-button
			class="go_back_button"
			[epic-tooltip]="textCloseChatModal"
			[epic-tooltip-background-color]="'var(--color__black)'"
			(click)="collapseWindow()"
			[iconName]="'close'" />
	</div>
	<div
		[ngClass]="{ 'chat-modal': !isCollapsed, 'chat-modal-collapsed': isCollapsed }"
		*ngIf="isVisible">
		<div
			*ngIf="!isCollapsed"
			class="chat-modal-container overflow">
			<chat-window
				*ngIf="isWindow"
				[isChatModal]="true" />
			<chat-menu
				*ngIf="isMenu"
				[isChatModal]="true" />
		</div>
		<div
			*ngIf="isCollapsed"
			class="chat-modal-collapsed-button">
			<epic-icon-button
				class="go_back_button"
				[epic-tooltip]="textOpenChatModal"
				[epic-tooltip-background-color]="'var(--color__black)'"
				(click)="collapseWindow()"
				[iconName]="'chat-square-dots'" />
			@if (chatService.newMessages) {
				<epic-tag
					[text]="chatService.newMessages > 99 ? '99+' : chatService.newMessages.toString()"
					[color]="'var(--color__white)'"
					class="unridden_messages" />
			}
		</div>
	</div>
}
