import { Component, EventEmitter, Input, Output } from '@angular/core'
import {
	ChatroomPatch,
	ChatroomRead,
	ChatroomsService,
	ChatroomUser,
	ChatroomUserAdd,
	UserRead,
	UsersService,
} from '@app/generated'
import { FormGroup } from '@angular/forms'
import { NotificationService } from '@core/services/notification.service'
import { LoaderService } from '@core/services/loader.service'
import { ModalService } from '@core/services/modal.service'
import { AuthService } from '@app/auth/services/auth.service'
import { ChatService } from '@app/chat/services/chat.service'
import { finalize } from 'rxjs'
import { AddChatFormSchema, createChatForm } from '@app/chat/forms/chat-add/chat-add.form'
import { ConfirmationComponent } from '@core/components/confirmation/confirmation.component'

@Component({
	selector: 'chat-update',
	templateUrl: './chat-update.form.html',
	styleUrl: './chat-update.form.scss',
})
export class ChatUpdateForm {
	users: UserRead[] = []
	usersSearchValue: string = ''
	usersSearchLoading: boolean = false
	current_userId: string | null = ''
	@Input() form: FormGroup<AddChatFormSchema> = createChatForm()
	@Input() conversation: ChatroomRead | undefined
	@Input() isChatInfo: boolean = false
	@Input() isChatModal: boolean = false
	@Output() chatCreated: EventEmitter<boolean> = new EventEmitter()
	protected readonly textWriteChatName = $localize`Write chat name...`
	protected readonly textAddParticipants = $localize`Add new participants`
	protected readonly textChangeChatName = $localize`Change chat name`
	protected readonly textRemoveParticipant = $localize`Remove participant`
	protected readonly textSetAsAdministrator = $localize`Set as administrator`
	protected readonly textSetAsUser = $localize`Set as user`

	constructor(
		private usersService: UsersService,
		private notificationService: NotificationService,
		private chatRoomService: ChatroomsService,
		private loaderService: LoaderService,
		private modalService: ModalService,
		private authService: AuthService,
		private chatService: ChatService,
	) {}

	ngOnInit() {
		this.current_userId = this.authService.getUserIdFromToken()
		if (this.conversation) {
			const name = this.conversation.chatroom_name
			if (name) {
				this.form.controls.name.setValue(name)
			}
		}
	}

	ngOnChanges() {
		if (this.conversation) {
			const name = this.conversation.chatroom_name
			if (name) {
				this.form.controls.name.setValue(name)
			}
		}
	}

	handleUsersSearchValueChanges(value: string): void {
		if (this.conversation?.users) {
			this.usersService.getUsers(0, 15, value, false).subscribe({
				next: response => {
					this.users = []
					const conversationUserIds = this.conversation?.users?.map(user => user.user.id) || []
					response.items.forEach(user => {
						if (!conversationUserIds.includes(user.id) && user.id !== this.current_userId) {
							this.users.push(user)
						}
					})
				},
				error: () => {
					this.notificationService.error($localize`Failed to fetch entities`)
				},
			})
		}
	}

	chatUpdate(): void {
		this.form.controls.participants.value.forEach(value => {
			const data: ChatroomUserAdd = {
				user_id: value.id,
			}
			if (!data || this.form.invalid) {
				this.notificationService.error('Form contains errors!')
				return
			}
			this.loaderService.enable()
			if (this.conversation) {
				this.chatRoomService
					.chatroomAddUser(this.conversation?.id, data)
					.pipe(
						finalize(() => {
							this.loaderService.disable()
						}),
					)
					.subscribe({
						next: value => {
							this.chatCreated.emit(true)
							this.chatService.notifyChatListUpdated()
							this.notificationService.success($localize`Chat user has been added`)
							if (this.conversation) {
								this.conversation.users = value.users
							}
						},
						error: err => {
							let message = $localize`Was not able to add chat`
							if (err.code === 422) {
								message = $localize`Form contains errors`
							}
							this.notificationService.error(message)
							this.loaderService.disable()
						},
					})
			}
			return
		})
		return
	}

	removeParticipant(userId: string) {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Warning`
			modal.componentInstance.message = $localize`Are you sure you want to remove this user from chat?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				if (this.conversation?.id) {
					this.chatRoomService.chatroomRemoveUser(this.conversation.id, userId).subscribe({
						next: () => {
							this.notificationService.success($localize`Successfully removed participant`)
							this.chatService.notifyChatListUpdated()
							if (this.conversation && this.conversation.users) {
								this.conversation.users = this.conversation.users.filter(user => user.user.id !== userId)
							}
						},
						error: () => {
							this.notificationService.error($localize`Something goes wrong during removing participant`)
						},
					})
				}
			}
		})
	}

	addNewParticipants() {
		this.chatUpdate()
		this.form.controls.participants.reset()
		this.usersSearchValue = ''
		this.users = []
	}

	changeChatName() {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Warning`
			modal.componentInstance.message = $localize`Are you sure you want to change this chat name?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				const chatroomPatch: ChatroomPatch = {
					chatroom_name: this.form.controls.name.value,
				}
				if (this.conversation) {
					this.chatRoomService.patchChatroom(this.conversation.id, chatroomPatch).subscribe({
						next: () => {
							this.notificationService.success($localize`Chatroom name has been updated!`)
							this.chatService.notifyChatListUpdated()
						},
						error: () => {
							this.notificationService.error($localize`Something goes wrong during chatroom name updating!`)
						},
					})
				}
			}
		})
	}

	showChatRole(isChatroomAdmin: boolean | undefined): string {
		if (isChatroomAdmin) {
			return $localize`Administrator`
		}
		return $localize`User`
	}

	grantPrivilige(user: ChatroomUser) {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Warning`
			if (!user.is_chatroom_admin) {
				modal.componentInstance.message = $localize`Are you sure you want to set this user as administrator?`
			} else {
				modal.componentInstance.message = $localize`Are you sure you want to set this user as user?`
			}
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed) {
				if (this.conversation) {
					this.chatRoomService
						.chatroomChangeUserPermissions(this.conversation.id, user.user.id, !user.is_chatroom_admin)
						.subscribe({
							next: () => {
								this.notificationService.success($localize`Successfully set chat role`)
								this.conversation?.users?.forEach(u => {
									if (u.user.id === user.user.id) {
										u.is_chatroom_admin = !u.is_chatroom_admin
									}
								})
							},
							error: () => {
								this.notificationService.error($localize`Failed to set chat role`)
							},
						})
				}
			}
		})
	}
}
