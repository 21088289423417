<form>
	<div
		class="margin__bottom--medium"
		i18n>
		Chat name
	</div>
	<epic-form-field>
		<epic-input
			[placeholder]="textWriteChatName"
			[control]="form.controls.name">
		</epic-input>
	</epic-form-field>
	<div
		class="margin__bottom--medium margin__top--medium"
		i18n>
		Choose participants
	</div>
	<epic-form-field>
		<epic-autocomplete
			(searchChange)="handleUsersSearchValueChanges($event)"
			[(searchValue)]="usersSearchValue"
			[isLoading]="usersSearchLoading"
			[multiSelect]="true"
			[customSelectedValues]="true"
			[control]="form.controls.participants">
			<div
				class="epic-select__custom-values"
				selected-values>
				<span
					*ngFor="let status of form.controls.participants.value"
					class="epic-select__value-round"
					>{{ status.first_name + ' ' + status.last_name }}</span
				>
			</div>
			@for (user of users; track user) {
			<epic-option
				[text]="user.first_name + ' ' +  user.last_name"
				[value]="user" />
			} @empty {
			<epic-option
				[isPlaceholder]="true"
				[showCheckbox]="false"
				*ngIf="!usersSearchLoading">
				@if (usersSearchValue.length) {
				<span i18n>No user matching was found.</span>
				} @else {
				<span i18n>Start typing the user name to display a list of matching results.</span>
				}
			</epic-option>
			}
		</epic-autocomplete>
	</epic-form-field>
	<div class="margin__top--medium justify__end">
		<epic-button
			(onClick)="onSubmit()"
			[id]="'chatFormAddNewChat'">
			<epic-icon name="chat-plus_outline" />
			<span>{{ textAddChat }}</span>
		</epic-button>
	</div>
</form>
