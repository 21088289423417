import { Component, EventEmitter, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, NgForm } from '@angular/forms'

@Component({
	selector: 'epic-form',
	standalone: true,
	imports: [CommonModule, FormsModule],
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss'],
})
export class FormComponent {
	@Output() onSubmit = new EventEmitter<NgForm>()

	/**
	 * Handles the form submission event by emitting the ngForm object.
	 *
	 * @param {NgForm} ngForm - The NgForm object representing the submitted form.
	 *
	 * @return {void}
	 */
	handleFormSubmission(ngForm: NgForm): void {
		this.onSubmit.emit(ngForm)
	}
}
