<div class="chat-window">
	<div class="chat-window__details">
		<div [class]="isChatModal ? ' chat-window__details-modal-title' : 'chat-window__details-title'">
			@if (isChatroomSelected) {
				@if (chatroomDetails?.is_private) {
					<span>{{ username }}</span>
				} @else {
					<span>{{ chatroomDetails?.chatroom_name }}</span>
				}
			} @else {
				<span i18n>No chat selected</span>
			}
		</div>
		<div
			*ngIf="isChatroomSelected"
			class="chat-window__details-controls">
			@if (!isUpdatingChat) {
				@if (!isChatModal) {
					<epic-icon-button
						[epic-tooltip]="textUpdateChat"
						[epic-tooltip-background-color]="'var(--color__black)'"
						*ngIf="chatRoomAdminId?.includes(current_user.id) && !chatroomDetails?.is_private"
						(click)="updateChat()"
						[iconName]="'edit'" />
					<epic-icon-button
						[epic-tooltip]="textChatInfo"
						*ngIf="!chatroomDetails?.is_private"
						[epic-tooltip-background-color]="'var(--color__black)'"
						(click)="openChatInfoModal(chatroomDetails)"
						[iconName]="'chat-square-dots'" />
					<epic-icon-button
						[epic-tooltip]="textLeaveChat"
						*ngIf="!chatroomDetails?.is_private"
						[epic-tooltip-background-color]="'var(--color__black)'"
						(click)="leaveChat(chatroomId)"
						class="chat-window__details-controls-black"
						[iconName]="'logout'" />
					<epic-icon-button
						[epic-tooltip]="textDeleteChat"
						[epic-tooltip-background-color]="'var(--color__black)'"
						*ngIf="chatRoomAdminId?.includes(current_user.id) && !chatroomDetails?.is_private"
						(click)="deleteChat(chatroomId)"
						[iconName]="'delete'" />
				} @else {
					<epic-icon-button
						class="go_back_button"
						[epic-tooltip]="textGoBack"
						[epic-tooltip-background-color]="'var(--color__black)'"
						(click)="goBack()"
						[iconName]="'arrow-left'" />
					<epic-icon-button
						#actions
						*ngIf="!chatroomDetails?.is_private"
						[epic-tooltip]="textGoBack"
						[epic-tooltip-background-color]="'var(--color__black)'"
						[iconName]="'more'" />
					<epic-popover
						class="font__small"
						*ngIf="buttonElement"
						[parentReference]="buttonElement.nativeElement">
						<div
							popover-header
							i18n>
							Chat options
						</div>
						<div
							class="epic-popover__list"
							popover-content>
							<div
								class="epic-popover__item"
								*ngIf="chatRoomAdminId?.includes(current_user.id)"
								(click)="openUpdateChatModal()"
								i18n>
								Update chat
							</div>
							<div
								class="epic-popover__item"
								*ngIf="chatRoomAdminId?.includes(current_user.id)"
								(click)="openChatInfoModal(chatroomDetails)"
								i18n>
								Chat info
							</div>
							<div
								class="epic-popover__item"
								(click)="leaveChat(chatroomId)"
								i18n>
								Leave chat
							</div>
							<div
								*ngIf="chatRoomAdminId?.includes(current_user.id)"
								class="epic-popover__item"
								(click)="deleteChat(chatroomId)"
								i18n>
								Delete chat
							</div>
						</div>
					</epic-popover>
				}
			} @else {
				<epic-icon-button
					class="go_back_button"
					[epic-tooltip]="textGoBack"
					[epic-tooltip-background-color]="'var(--color__black)'"
					(click)="goBack()"
					[iconName]="'arrow-left'" />
			}
		</div>
	</div>
	<div
		class="chat-window__content"
		*ngIf="!isUpdatingChat"
		(scroll)="onScroll($event)"
		#chatContent>
		@if (messages.items.length == 0) {
			<div
				class="chat-window__content-message chat-window__content-message-placeholder"
				i18n>
				There is no messages in this chat
			</div>
		}
		@for (message of messages.items; track message.id; let i = $index) {
			@if (message.created_by?.id != current_user.id) {
				<div class="chat-window__content-message">
					<div class="chat__bubble chat__bubble--rcvd">
						{{ message.text }}
					</div>
					@if (shouldShowMetadata(i)) {
						<div class="chat__bubble--description-rcvd">
							<div class="chat-window__author-avatar margin__right--small">
								<avatar [avatarName]="message.created_by?.avatar_file_name ?? undefined" />
							</div>
							<span>{{ message.created_by?.first_name + ' ' + message.created_by?.last_name }}</span>
							<span class="chat-window__content-date">
								{{ showDate(message.created_at) }}
							</span>
							@if (calculateTimeDifference(message)) {
								<span class="chat-window__content-date">{{ textEditedMessage }}</span>
							}
						</div>
					}
				</div>
			} @else {
				<div
					class="chat-window__content-message"
					(mouseenter)="toggleHover(i, true)"
					(mouseleave)="toggleHover(i, false)">
					<div class="chat__bubble chat__bubble--sent">
						{{ message.text }}
					</div>
					<div
						#messageActions
						class="message-options">
						<epic-icon-button
							*ngIf="hover[i]"
							[iconName]="'more'" />
					</div>
					<epic-popover
						class="message-card__menu-button"
						[parentReference]="messageActions">
						<div
							popover-header
							class="font__medium font-weight__bold"
							i18n>
							Message options
						</div>
						<div popover-content>
							<div
								class="epic-popover__item font__small"
								(click)="updateMessage(message)"
								i18n>
								Edit message
							</div>
							<div
								class="epic-popover__item font__small"
								(click)="deleteMessage(message)"
								i18n>
								Delete message
							</div>
						</div>
					</epic-popover>

					@if (shouldShowMetadata(i)) {
						<div class="chat__bubble--description-sent">
							<span class="chat-window__content-date">
								{{ showDate(message.created_at) }}
							</span>
							@if (calculateTimeDifference(message)) {
								<span class="chat-window__content-date">{{ textEditedMessage }}</span>
							}
						</div>
					}
				</div>
			}
		}
	</div>
	<div
		*ngIf="!isUpdatingChat"
		class="chat-window__bottom message-input-container">
		@if (isChatroomSelected) {
			<epic-textarea
				#messageInput
				[placeholder]="textWriteMessage"
				[control]="messageText"
				[rows]="1"
				(input)="autoResize($event)"
				(keydown.enter)="sendMessageOnEnter($event)" />
			<epic-button
				(onClick)="isEditing ? updateMessageConfirm() : sendMessage()"
				class="send-message">
				@if (!isEditing) {
					<epic-icon name="send"></epic-icon>
				} @else {
					<epic-icon name="tick-bold"></epic-icon>
				}
			</epic-button>
			<epic-button
				*ngIf="isEditing"
				(onClick)="cancelEdit()"
				class="send-message resized-x">
				<epic-icon name="remove"></epic-icon>
			</epic-button>
		} @else {
			<div
				class="chat-placeholder"
				i18n>
				Choose chat or make new to start conversation
			</div>
		}
	</div>
	<div *ngIf="isUpdatingChat && isChatroomSelected">
		<chat-update [conversation]="chatroomDetails"></chat-update>
	</div>
</div>
