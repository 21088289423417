<div class="message-card">
	<div
		(click)="handleClick(conversation, user)"
		class="message-card__container c-pointer">
		<div [class]="!isRidden ? 'unridden message-card__content' : 'message-card__content'">
			<div class="message-card__header-container">
				<div class="message-card__header">
					<div class="message-card__avatar">
						<avatar
							[group]="!user"
							[avatarName]="user?.avatar_file_name ?? undefined" />
					</div>
					<div class="message-card__title">
						{{ participants }}
					</div>
				</div>
				@if (conversation?.unridden_messages_count) {
					<epic-tag
						[text]="
							conversation?.unridden_messages_count! > 99 ? '99+' : conversation?.unridden_messages_count!.toString()
						"
						[color]="'var(--color__white)'"
						class="message-card__counter" />
				}
			</div>
			<div class="message-card__message">
				<div class="message-card__message-content">{{ lastMessageFrom }} {{ lastMessage }}</div>
				<div class="message-card__message-time">{{ calculateTimeFromDate() }}</div>
			</div>
		</div>
	</div>
	<div class="message-card__menu">
		<button
			#buttonElement
			*ngIf="(!user || conversation) && !isPrivate"
			class="c-pointer message-card__menu-button">
			<epic-icon name="more" />
		</button>
	</div>
</div>
<epic-popover
	class="font__small"
	*ngIf="(!user || conversation) && buttonElement"
	[parentReference]="buttonElement.nativeElement">
	<div
		popover-header
		i18n>
		Chat options
	</div>
	<div
		class="epic-popover__list"
		popover-content>
		<div
			class="epic-popover__item"
			*ngIf="conversation?.chat_room_adminId?.includes(current_userId)"
			(click)="openUpdateChat(conversation)"
			i18n>
			Update chat
		</div>
		<div
			class="epic-popover__item"
			(click)="openChatInfo(conversation?.chatroom)"
			i18n>
			Chat info
		</div>
		<div
			class="epic-popover__item"
			(click)="leaveChat(conversation?.chatroom?.id)"
			i18n>
			Leave chat
		</div>
		<div
			*ngIf="conversation?.chat_room_adminId?.includes(current_userId)"
			class="epic-popover__item"
			(click)="deleteChat(conversation?.chatroom?.id)"
			i18n>
			Delete chat
		</div>
	</div>
</epic-popover>
