import { Component, Input } from '@angular/core'
import { config } from '@config'
import { NgOptimizedImage } from '@angular/common'

@Component({
	selector: 'avatar',
	standalone: true,
	imports: [NgOptimizedImage],
	templateUrl: './avatar.component.html',
	styleUrl: './avatar.component.scss',
})
export class AvatarComponent {
	@Input() avatarName?: string
	@Input() group: boolean = false

	/**
	 * Returns the URL of the avatar image.
	 *
	 * @returns {string} The URL of the avatar image.
	 */
	get URL(): string {
		if (this.avatarName && this.avatarName != '') {
			return `${config.api.url}/api/avatar/${this.avatarName}`
		} else {
			if (this.group) {
				return 'assets/group.png'
			} else {
				return 'assets/user.png'
			}
		}
	}
}
