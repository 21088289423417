<form>
	@if (!isChatInfo) {
	<div
		class="margin__bottom--medium"
		i18n>
		Chat name
	</div>
	<epic-form-field>
		<epic-input
			class="margin__bottom--medium"
			[placeholder]="textWriteChatName"
			[control]="form.controls.name">
		</epic-input>
	</epic-form-field>
	<epic-button
		class="margin__top--medium"
		(onClick)="changeChatName()"
		[text]="textChangeChatName">
	</epic-button>
	} @else {
	<div
		class="margin__bottom--medium"
		i18n>
		Chat Name: {{ conversation?.chatroom_name }}
	</div>
	}
	<div class="margin__bottom--medium margin__top--medium">
		<div
			class="margin__bottom--medium"
			i18n>
			Participants
		</div>
		<div class="participants-list">
			<epic-table>
				@for (user of conversation?.users; track user.user.id) {
				<tr>
					<td class="participants-list__participants">{{ user.user.first_name + " " + user.user.last_name }}</td>
					<td class="participants-list__roles">{{ showChatRole(user.is_chatroom_admin) }}</td>
					<td *ngIf="!isChatInfo">
						<div class="participants-list__controls">
							<epic-button
								(onClick)="grantPrivilige(user)"
								[epic-tooltip]="textSetAsAdministrator"
								[epic-tooltip-background-color]="'var(--color__black)'"
								*ngIf="user.user.id != current_userId && !user.is_chatroom_admin"
								class="participant-remove">
								<epic-icon name="user-shield" />
							</epic-button>
							<epic-button
								(onClick)="grantPrivilige(user)"
								[epic-tooltip]="textSetAsUser"
								[epic-tooltip-background-color]="'var(--color__black)'"
								*ngIf="user.user.id != current_userId && user.is_chatroom_admin"
								class="participant-remove">
								<epic-icon name="user-down" />
							</epic-button>
							<epic-button
								(onClick)="removeParticipant(user.user.id)"
								[epic-tooltip]="textRemoveParticipant"
								[epic-tooltip-background-color]="'var(--color__black)'"
								*ngIf="user.user.id != current_userId "
								class="participant-remove">
								<epic-icon name="user-x" />
							</epic-button>
						</div>
					</td>
				</tr>
				}
			</epic-table>
		</div>
	</div>
	@if (!isChatInfo) {
	<div
		class="margin__bottom--medium"
		i18n>
		Choose participants
	</div>
	<epic-form-field>
		<epic-autocomplete
			(searchChange)="handleUsersSearchValueChanges($event)"
			[(searchValue)]="usersSearchValue"
			[isLoading]="usersSearchLoading"
			[multiSelect]="true"
			[customSelectedValues]="true"
			[control]="form.controls.participants">
			<div
				class="epic-select__custom-values"
				selected-values>
				<span
					*ngFor="let status of form.controls.participants.value"
					class="epic-select__value-round"
					>{{ status.first_name + ' ' + status.last_name }}</span
				>
			</div>
			@for (user of users; track user) {
			<epic-option
				[text]="user.first_name + ' ' +  user.last_name"
				[value]="user" />
			} @empty {
			<epic-option
				[isPlaceholder]="true"
				[showCheckbox]="false"
				*ngIf="!usersSearchLoading">
				@if (usersSearchValue.length) {
				<span i18n>No user matching was found.</span>
				} @else {
				<span i18n>Start typing the user name to display a list of matching results.</span>
				}
			</epic-option>
			}
		</epic-autocomplete>
	</epic-form-field>
	<div class="margin__top--medium justify__end">
		<epic-button
			(onClick)="addNewParticipants()"
			[id]="'chatFormAddNewChat'">
			<epic-icon name="chat-plus_outline" />
			<span>{{ textAddParticipants }}</span>
		</epic-button>
	</div>
	}
</form>
