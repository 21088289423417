<div class="chat-list__list">
	@if (!search) {
		@for (conversation of conversationList; track conversation.chatroom.id) {
			<message-card
				[conversation]="conversation"
				[isDashboard]="isDashboard" />
		}
	} @else {
		<div
			class="title"
			i18n>
			Users
		</div>
		@if (searchList.users.length <= 0) {
			<div
				class="not-found"
				i18n>
				No users match search value
			</div>
		}
		@for (result of searchList?.users; track result.id) {
			<message-card [user]="result" />
		}
		<div
			class="title"
			i18n>
			Chats
		</div>
		@if (searchList.chats.length <= 0) {
			<div
				class="not-found"
				i18n>
				No chats match search value
			</div>
		}
		@for (conversation of searchList?.chats; track conversation.chatroom.id) {
			<message-card
				[conversation]="conversation"
				[isDashboard]="isDashboard" />
		}
	}
</div>

<!--<div class="chat-list">-->
<!--	@for (conversation of conversationList; track conversation.chatroom.id) {-->
<!--		<div class="chat-list__chat-group full-width">-->
<!--			<div class="chat-list__chat-group-field chat_list__chat-group-icon">-->
<!--				<epic-icon name="chat-square-dots" />-->
<!--			</div>-->
<!--			<div-->
<!--				(click)="changeChatroom(conversation)"-->
<!--				class="chat-list__chat-group-field chat-list__chat-group-message c-pointer">-->
<!--				<p class="chat-list__chat-group-field-title">{{ conversation.chatroom.chatroom_name }}</p>-->
<!--				<p class="chat-list__chat-group-field-content">{{ conversation.last_message.text }}</p>-->
<!--			</div>-->
<!--			<div #actions>-->
<!--				<epic-icon-button-->
<!--					class="chat-list__chat-group-icon"-->
<!--					[iconName]="'more'" />-->
<!--			</div>-->
<!--			<epic-popover-->
<!--				class="message-card__menu-button"-->
<!--				[parentReference]="actions">-->
<!--				<div-->
<!--					popover-header-->
<!--					class="font__medium font-weight__bold"-->
<!--					i18n>-->
<!--					Chat options-->
<!--				</div>-->
<!--				<div popover-content>-->
<!--					<div-->
<!--						class="epic-popover__item font__small"-->
<!--						*ngIf="conversation.chat_room_adminId.includes(current_userId)"-->
<!--						(click)="openUpdateChatModal(conversation)"-->
<!--						i18n>-->
<!--						Update chat-->
<!--					</div>-->
<!--					<div-->
<!--						class="epic-popover__item font__small"-->
<!--						(click)="openChatInfoModal(conversation.chatroom)"-->
<!--						i18n>-->
<!--						Chat info-->
<!--					</div>-->
<!--					<div-->
<!--						class="epic-popover__item font__small"-->
<!--						(click)="leaveChat(conversation.chatroom.id)"-->
<!--						i18n>-->
<!--						Leave chat-->
<!--					</div>-->
<!--					<div-->
<!--						*ngIf="conversation.chat_room_adminId.includes(current_userId)"-->
<!--						class="epic-popover__item font__small"-->
<!--						(click)="deleteChat(conversation.chatroom.id)"-->
<!--						i18n>-->
<!--						Delete chat-->
<!--					</div>-->
<!--				</div>-->
<!--			</epic-popover>-->
<!--		</div>-->
<!--	}-->
<!--</div>-->
