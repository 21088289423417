import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { NotificationService } from '@core/services/notification.service'
import { ChatMessageCreate, ChatMessageRead, ChatMessageReadList, ChatroomRead, ChatroomsService } from '@app/generated'
import { ChatService } from '@app/chat/services/chat.service'
import { finalize, Subscription } from 'rxjs'
import { LoaderService } from '@core/services/loader.service'
import { AuthService } from '@app/auth/services/auth.service'
import { SocketsService } from '@app/general/services/sockets.service'
import { Logger } from '@app/general/utils/logger.util'
import { ModalService } from '@core/services/modal.service'
import { ConfirmationComponent } from '@core/components/confirmation/confirmation.component'
import { ChatRoomReadSchema } from '@app/chat/components/chat-list/chat-list.component'

export interface CurrentUserSchema {
	id: string
}

@Component({
	selector: 'chat-window',
	templateUrl: './chat-window.component.html',
	styleUrl: './chat-window.component.scss',
})
export class ChatWindowComponent {
	@Input() isChatModal: boolean = false
	@ViewChild('actions') buttonElement: ElementRef<HTMLElement> | undefined

	chatroomDetails?: ChatroomRead
	chatroomId: string | undefined = ''
	editedMessageId: number | null = null
	editedMessageText: string = ''
	isEditing: boolean = false
	loading: boolean = false
	offset: number = 0
	pagination: number = 25
	isUpdatingChat: boolean = false
	username: string = ''
	updatingMessage?: ChatMessageRead
	messages: ChatMessageReadList = {
		total: 0,
		items: [],
	}
	chatRoomAdminId: string[] | undefined = []
	current_user: CurrentUserSchema = {
		id: '',
	}
	isChatroomSelected: boolean = false
	messageText: FormControl<string | null> = new FormControl(null)
	hover: { [key: number]: boolean } = {}
	handleMessageSubscription?: Subscription
	protected readonly textWriteMessage = $localize`Write message...`
	protected readonly textEditedMessage = $localize`- edited`
	protected readonly textDeletedMessage = $localize`Delete this message`
	protected readonly textUpdateChat = $localize`Update chat`
	protected readonly textChatInfo = $localize`Chat info`
	protected readonly textLeaveChat = $localize`Leave chat`
	protected readonly textDeleteChat = $localize`Delete chat`
	protected readonly textGoBack = $localize`Go back`
	protected readonly textMoreOptions = $localize`Options`
	private readonly logger: Logger = new Logger(ChatWindowComponent.name, {
		infoBackground: '#fdd0ff',
		infoColor: '#1e1e1e',
	})
	private chatroomIdSubscription: Subscription = new Subscription()
	private updateWindowSubscription: Subscription = new Subscription()
	private leftChatSubscription: Subscription = new Subscription()
	@ViewChild('chatContent') private chatContent: ElementRef | undefined

	constructor(
		private notificationService: NotificationService,
		private chatService: ChatService,
		private chatroomsService: ChatroomsService,
		private loaderService: LoaderService,
		private authService: AuthService,
		private socketService: SocketsService,
		private modalService: ModalService,
	) {}

	handleMessage(value: ChatMessageRead, messageID: any) {
		const existingMessageIndex = this.messages.items.findIndex(msg => msg.id === value.id)

		if (messageID.action === 'CREATED') {
			if (existingMessageIndex === -1) {
				this.messages.items.push(value)
				this.scrollToBottom()
			}
		} else if (messageID.action === 'UPDATED') {
			if (existingMessageIndex !== -1) {
				this.messages.items[existingMessageIndex] = { ...this.messages.items[existingMessageIndex], ...value }
			}
		} else if (messageID.action === 'DELETED') {
			if (existingMessageIndex !== -1) {
				this.messages.items.splice(existingMessageIndex, 1)
			}
		}
	}

	initSubscriptions() {
		this.handleMessageSubscription = this.chatService.newMessageSocket.subscribe(socket => {
			if (socket.inChatroom) {
				this.handleMessage(socket.value, socket.message)
			}
		})
	}

	changeChatroom(chatroom: ChatRoomReadSchema, isupdating: boolean) {
		this.isUpdatingChat = isupdating
		this.chatroomId = chatroom?.chatroom.id
		this.offset = 0
		this.pagination = 25
		this.chatroomDetails = chatroom?.chatroom
		this.chatRoomAdminId = chatroom?.chat_room_adminId
		this.fetchMessages()
		this.sortMessagesByTime()
		if (chatroom) {
			this.isChatroomSelected = true
			this.chatService.newMessages -= chatroom.unridden_messages_count ? chatroom.unridden_messages_count : 0
			chatroom.unridden_messages_count = 0
		}
		if (chatroom.chatroom.is_private) {
			if (chatroom.chatroom.users) {
				chatroom.chatroom.users.forEach(value => {
					if (value.user.id != this.current_user.id) {
						this.username = value.user.first_name + ' ' + value.user.last_name
					}
				})
			}
		}
	}

	ngOnInit() {
		this.initSubscriptions()
		this.resetParameters()
		this.chatroomIdSubscription = this.chatService.currentId.subscribe(chatroom => {
			if (chatroom) {
				this.changeChatroom(chatroom.chatroom, chatroom.isUpdating)
			}
		})
		this.leftChatSubscription = this.chatService.leftChatNow.subscribe(id => {
			if (this.chatroomId == id) {
				this.resetParameters()
				if (this.isChatModal) {
					this.chatService.toogleWindow('menu')
				}
			}
		})
		const currentuser = this.authService.getUserIdFromToken()
		if (currentuser) {
			this.current_user.id = currentuser
		}
	}

	ngAfterViewInit() {
		this.scrollToBottom()
	}

	ngOnDestroy() {
		this.chatService.currentRoomId = ''
		if (this.handleMessageSubscription) {
			this.handleMessageSubscription.unsubscribe()
		}
	}

	getChatDetails() {
		if (this.chatroomId) {
			this.loaderService.enable()
			this.chatroomsService
				.getChatroom(this.chatroomId)
				.pipe(
					finalize(() => {
						this.loaderService.disable()
					}),
				)
				.subscribe({
					next: response => {
						this.chatroomDetails = response
						response.users?.forEach(user => {
							if (this.current_user.id == user.user.id && user.is_chatroom_admin && this.chatRoomAdminId) {
								this.chatRoomAdminId.push(user.user.id)
							}
						})
						this.isChatroomSelected = true
					},
					error: () => {
						this.notificationService.error($localize`Failed to load chat details`)
					},
				})
		} else {
			this.isChatroomSelected = false
		}
	}

	fetchMessages() {
		if (this.chatroomId) {
			this.chatroomsService
				.getChatMessages(this.chatroomId, this.offset, this.pagination, undefined, false)
				.pipe(finalize(() => {}))
				.subscribe({
					next: response => {
						response.items.sort((b, a) => {
							if (a.created_at && b.created_at) {
								return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
							} else if (a.created_at) {
								return -1
							} else if (b.created_at) {
								return 1
							} else {
								return 0
							}
						})
						this.messages.items = response.items
						this.scrollToBottom()
					},
					error: () => {
						this.notificationService.error($localize`Failed to load messages`)
					},
				})
		}
	}

	shouldShowMetadata(index: number): boolean {
		if (this.messages) {
			const currentMessage = this.messages.items[index]

			if (index === this.messages.items.length - 1) {
				return true
			}

			const nextMessage = this.messages.items[index + 1]

			if (currentMessage.created_by?.id !== nextMessage.created_by?.id) {
				return true
			}

			const currentMessageDate = new Date(currentMessage.created_at as string).getTime()
			const nextMessageDate = new Date(nextMessage.created_at as string).getTime()
			const timeDifference = nextMessageDate - currentMessageDate
			const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

			if (minutesDifference >= 10) {
				return true
			}
		}

		return false
	}

	scrollToBottom(): void {
		try {
			setTimeout(() => {
				if (this.chatContent) {
					this.chatContent.nativeElement.scrollTop = this.chatContent.nativeElement.scrollHeight
				}
			}, 0)
		} catch (err) {
			console.error('Could not scroll to bottom:', err)
		}
	}

	sortMessagesByTime() {
		this.messages.items.sort((a, b) => {
			if (a.created_at && b.created_at) {
				return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
			} else if (a.created_at) {
				return 1
			} else if (b.created_at) {
				return -1
			} else {
				return 0
			}
		})
	}

	showDate(dateString: string | null): string {
		if (dateString) {
			const actualDate = Date.now()
			const messageDate = new Date(dateString).getTime()
			const timeDifference = actualDate - messageDate
			const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

			if (daysDifference >= 1) {
				return this.parseDate(dateString) + ' ' + this.parseTime(dateString)
			} else {
				return this.parseTime(dateString)
			}
		}
		return ''
	}

	parseTime(dateString: string | null): string {
		if (dateString) {
			const date = new Date(dateString)
			const hours = String(date.getHours()).padStart(2, '0')
			const minutes = String(date.getMinutes()).padStart(2, '0')
			return `${hours}:${minutes}`
		}
		return ''
	}

	parseDate(dateString: string): string {
		const date = new Date(dateString)
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, '0')
		const day = String(date.getDate()).padStart(2, '0')
		return `${year}.${month}.${day}`
	}

	sendMessageOnEnter(event: Event) {
		const keyboardEvent = event as KeyboardEvent
		if (keyboardEvent.key === 'Enter' && !keyboardEvent.shiftKey) {
			if (this.isEditing) {
				keyboardEvent.preventDefault()
				this.updateMessageConfirm()
			} else {
				keyboardEvent.preventDefault()
				this.sendMessage()
			}
		}
	}

	sendMessage() {
		if (this.messageText.value) {
			if (this.messageText.value.length > 0) {
				const message = this.messageText.value
				const messageCreate: ChatMessageCreate = {
					text: message,
				}
				if (this.chatroomId) {
					this.chatroomsService.sendChatMessage(this.chatroomId, messageCreate).subscribe({
						next: response => {
							response.text = message
							this.messages.items.push(response)
							this.messageText.reset()
							this.resetTextareaHeight()
							this.scrollToBottom()
						},
						error: () => {
							this.notificationService.error($localize`Failed to send message`)
						},
					})
				}
			}
		}
	}

	leaveChat(chatroomId: string | undefined) {
		if (chatroomId) {
			this.chatService.leaveChat(chatroomId)
		}
	}

	deleteChat(chatroomId: string | undefined) {
		if (chatroomId) {
			this.chatService.deleteChat(chatroomId)
		}
	}

	openUpdateChatModal() {
		this.isUpdatingChat = true
		// if (conversation) this.chatService.openUpdateChatModal(conversation, false)
	}

	openChatInfoModal(conversation: ChatroomRead | undefined) {
		if (conversation) {
			this.chatService.openUpdateChatModal(conversation, true)
		}
	}

	toggleHover(index: number, state: boolean): void {
		this.hover[index] = state
	}

	startEditing(message: any): void {
		this.editedMessageId = message.id
		this.editedMessageText = message.text
	}

	cancelEdit(): void {
		this.isEditing = false
		this.updatingMessage = undefined
		this.messageText.setValue(null)
		this.resetTextareaHeight()
	}

	deleteMessage(message: ChatMessageRead) {
		this.modalService.open(ConfirmationComponent).then(modal => {
			modal.title = $localize`Danger`
			modal.componentInstance.message = $localize`Are you sure to delete this message?`
		})
		this.modalService.modalRef.closed.subscribe((confirmed: boolean) => {
			if (confirmed && this.chatroomId) {
				this.chatroomsService.deleteChatMessage(this.chatroomId, message.id).subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully deleted message!`)
						this.messages.items = this.messages.items.filter(msg => msg.id !== message.id)
					},
					error: () => {
						this.notificationService.error($localize`Something goes wrong during deleting message!`)
					},
				})
			}
		})
	}

	updateMessage(message: ChatMessageRead) {
		this.isEditing = true
		this.updatingMessage = message
		this.messageText.setValue(message.text)
		this.autoResize()
	}

	updateMessageConfirm() {
		if (this.isEditing && this.updatingMessage && this.messageText.value && this.chatroomId) {
			this.chatroomsService
				.updateChatMessage(this.chatroomId, this.updatingMessage.id, {
					text: this.messageText.value,
				})
				.subscribe({
					next: () => {
						this.notificationService.success($localize`Successfully updated message`)
						this.messages.items = this.messages.items.map(msg => {
							if (this.updatingMessage) {
								if (msg.id === this.updatingMessage.id) {
									return {
										...msg,
										text: this.messageText.value as string,
									}
								}
							}

							return msg
						})
						this.messageText.setValue(null)
						this.resetTextareaHeight()
						this.isEditing = false
					},
					error: () => {
						this.notificationService.error($localize`Something goes wrong during updating message`)
					},
				})
		}
	}

	resetTextareaHeight() {
		const textarea = document.querySelector('textarea') as HTMLTextAreaElement
		if (textarea) {
			textarea.style.height = 'auto'
		}
	}

	autoResize(event?: Event) {
		if (event) {
			const textarea = event.target as HTMLTextAreaElement
			textarea.style.height = 'auto'
			textarea.style.height = Math.min(textarea.scrollHeight, 100) + 'px'
		} else {
			const textarea = document.querySelector('textarea') as HTMLTextAreaElement
			textarea.style.height = 'auto'
			textarea.style.height = Math.min(textarea.scrollHeight, 100) + 'px'
		}
	}

	calculateTimeDifference(message: ChatMessageRead): boolean {
		if (message.created_at && message.updated_at) {
			const date1 = new Date(message.created_at).getTime()
			const date2 = new Date(message.updated_at).getTime()
			const timeDifference = date2 - date1
			const secondsDifference = Math.floor((timeDifference % (1000 * 60)) / 1000)
			return secondsDifference > 0
		}
		return false
	}

	onScroll(event: any) {
		const element = event.target
		if (element.scrollTop === 0) {
			const previousScrollHeight = element.scrollHeight
			this.offset += this.pagination
			this.fetchMoreMessages(previousScrollHeight)
		}
	}

	fetchMoreMessages(previousScrollHeight: number) {
		if (this.chatroomId) {
			this.chatroomsService
				.getChatMessages(this.chatroomId, this.offset, this.pagination, undefined, false)
				.pipe(finalize(() => {}))
				.subscribe({
					next: response => {
						response.items.sort((b, a) => {
							if (a.created_at && b.created_at) {
								return new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
							} else if (a.created_at) {
								return -1
							} else if (b.created_at) {
								return 1
							} else {
								return 0
							}
						})
						this.messages.items = [...response.items, ...this.messages.items]

						setTimeout(() => {
							if (this.chatContent) {
								this.chatContent.nativeElement.scrollTop =
									this.chatContent.nativeElement.scrollHeight - previousScrollHeight
							}
						}, 0)
					},
					error: () => {
						this.notificationService.error($localize`Failed to load more messages`)
					},
				})
		}
	}

	goBack() {
		if (this.isUpdatingChat) {
			this.isUpdatingChat = false
		} else {
			this.chatService.toogleWindow('menu')
			this.isUpdatingChat = false
		}
	}

	updateChat() {
		this.isUpdatingChat = true
	}

	resetParameters() {
		this.chatroomId = undefined
		this.offset = 0
		this.pagination = 25
		this.chatroomDetails = undefined
		this.chatRoomAdminId = undefined
		this.isUpdatingChat = false
		this.isChatroomSelected = false
		this.messages = {
			total: 0,
			items: [],
		}
	}
}
