import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core'
import { IconComponent } from '@core/components/icon/icon.component'
import { PopoverComponent } from '@core/components/popover/popover.component'
import { NotificationService } from '@core/services/notification.service'
import { ChatRoomReadSchema } from '@app/chat/components/chat-list/chat-list.component'
import { ChatService } from '@app/chat/services/chat.service'
import { ChatroomRead, UserRead } from '@app/generated'
import { NgIf } from '@angular/common'
import { AuthService } from '@app/auth/services/auth.service'
import { AvatarComponent } from '@app/users/components/avatar/avatar.component'
import { Router } from '@angular/router'
import { getTextColor } from '@app/general/utils/color-manipulation'
import { TagComponent } from '@core/components/tag/tag.component'
import { color } from 'chart.js/helpers'

@Component({
	selector: 'message-card',
	standalone: true,
	templateUrl: './message-card.component.html',
	styleUrl: './message-card.component.scss',
	imports: [IconComponent, PopoverComponent, NgIf, AvatarComponent, TagComponent],
})
export class MessageCardComponent implements OnChanges {
	@Input() participants?: string[] = []
	@Input() user?: UserRead
	@Input() lastMessage?: string = ''
	@Input() dateOfMessage?: string = ''
	@Input() lastMessageFrom?: string = ''
	@Input() conversation?: ChatRoomReadSchema
	@Input() isDashboard: boolean = false
	isRidden: boolean = true
	@ViewChild('buttonElement') buttonElement: ElementRef<HTMLElement> | undefined

	messageCount: number = 0
	current_userId: string = ''
	isPrivate: boolean = false
	constructor(
		private notificationService: NotificationService,
		private chatService: ChatService,
		private authService: AuthService,
		private cd: ChangeDetectorRef,
		private router: Router,
	) {}

	ngOnInit() {
		const userId = this.authService.getUserIdFromToken()
		if (userId) {
			this.current_userId = userId
		}
		this.updateFields()
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['conversation']) {
			this.updateFields()
		}
	}

	private updateFields() {
		if (this.conversation) {
			this.participants = [this.conversation.chatroom.chatroom_name as string]
			this.lastMessage = this.conversation.last_message.text
			this.dateOfMessage = this.conversation.last_message.created_at as string
			this.messageCount = this.conversation.chatroom.new_messages_count as number
			if (this.conversation.chatroom.is_private) {
				this.conversation.chatroom.users?.forEach(value => {
					if (value.user.id != this.current_userId) {
						this.user = value.user
					}
				})
			}
			if (this.conversation?.unridden_messages_count) {
				if (this.conversation?.unridden_messages_count > 0) {
					this.isRidden = false
				} else {
					this.isRidden = true
				}
			}
		}
		if (this.user) {
			this.participants = [this.user.first_name + ' ' + this.user.last_name]
		}
		if (this.conversation?.chatroom) {
			this.isPrivate = this.conversation.chatroom.is_private as boolean
		}
	}

	calculateTimeFromDate(): string | undefined {
		try {
			if (this.dateOfMessage) {
				const actualDate = Date.now()
				const messageDate = new Date(this.dateOfMessage).getTime()

				if (isNaN(messageDate)) {
					console.error('Invalid date format')
					return ''
				}
				const timeDifference = actualDate - messageDate
				const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
				const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
				const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60))

				if (daysDifference >= 1 && daysDifference <= 6) {
					if (daysDifference >= 1) {
						return daysDifference.toString() + $localize`day`
					} else {
						return daysDifference.toString() + $localize`days`
					}
				} else if (hoursDifference >= 1 && hoursDifference <= 23) {
					return hoursDifference.toString() + $localize`h`
				} else if (minutesDifference <= 59 && minutesDifference >= 0) {
					return minutesDifference.toString() + $localize`m`
				} else {
					return new Date(this.dateOfMessage).getDate().toString()
				}
				return ''
			}
			return ''
		} catch (e) {
			this.notificationService.error('Something goes wrong with calculating data from message')
			return ''
		}
	}

	openUpdateChat(conversation: ChatRoomReadSchema | undefined) {
		if (conversation) {
			if (this.isDashboard) {
				this.chatService.changeCollapsed(false)
			}
			this.chatService.changeId(conversation, true)
		}
	}

	openChatInfo(conversation: ChatroomRead | undefined) {
		if (conversation) {
			this.chatService.openUpdateChatModal(conversation, true)
		}
	}

	leaveChat(chatroomId: string | undefined) {
		if (chatroomId) {
			this.chatService.leaveChat(chatroomId)
		}
	}

	deleteChat(chatroomId: string | undefined) {
		if (chatroomId) {
			this.chatService.deleteChat(chatroomId)
		}
	}

	changeChatroom(conversation: ChatRoomReadSchema | undefined): void {
		if (conversation) {
			this.chatService.changeId(conversation, false)
		}
	}

	handleClick(conversation: ChatRoomReadSchema | undefined, user: UserRead | undefined) {
		if (conversation) {
			this.isRidden = true
			if (this.isDashboard) {
				this.chatService.changeCollapsed(false)
			}
			this.changeChatroom(conversation)
		} else if (user) {
			this.chatService.informAddedChatWithUser(user)
		}
	}

	protected readonly getTextColor = getTextColor
	protected readonly color = color
}
